import React, {useEffect, useState} from "react"
import { Heading, Row, Text } from "@vinomofo/components";
import Helmet from "react-helmet"

import PageSection from "../component-factories/page-section"

import {
  useMarketContext,
  hreflangTags,
} from "lib-vinomofo/contexts/market-context"

import PageLayout from "../components/page-layout"
import classNames from "lib-vinomofo/utils";
import * as styles2 from "../components/banner-group/banner-group.module.scss";

// const ranks = [];
// Object.values(JSON.parse(localStorage.getItem("ranks")) || []).map((val) => {
//   ranks.push({
//     "key": "varietals",
//     "value":val
//   });
// });

const data = {
  "site": {
    "siteMetadata": {
      "title": "Vinomofo",
      "description": "\n      Vinomofo is the best wine deals site\n      on the planet. Good wines, real people and epic deals,\n      without all the bowties and bs."
    }
  },
  "sanityPage": {
    "title": "Buy Wine Online",
    "description": null,
    "sections": [
      {
        "_type": "pageSection",
        "fullWidth": false,
        "spaceAfter": 4,
        "spaceBefore": 0,
        "backgroundColor": null,
        "contentBlock": {
          "_type": "compoundProductBlockPreferredWines",
          "title": "Vino",
          "link": {
            "text": "View all wines",
            "uri": {
              "link": "https://www.vinomofo.com/wines",
              "route": null
            }
          },
          "searches": [
            {
              "title": "Preferred Wines",
              "filters": []
            }
          ]
        }
      }
    ]
  }
};

const PreferredWines = () => {
  const market = useMarketContext()

  const { siteMetadata } = data.site
  const { ...page } = data.sanityPage

  const pageTitle = `${page.title} | ${siteMetadata.title} ${market.name}`
  const pageDescription = page.description || siteMetadata.description
  const canonicalUrl = `${market.canonical_url}/`
  const [localRanks, setLocalRanks] = useState([]);
  const classes = classNames([
    styles2.banner
  ])

  useEffect(() => {
    const ranks = JSON.parse(localStorage.getItem("ranks")) || [];
    setLocalRanks(ranks);
  }, []);

  return (
    <PageLayout>
      <Helmet>
        <title>{pageTitle}</title>

        {hreflangTags.map(tag => {
          return (
            <link
              key={tag.lang}
              rel="alternate"
              href={`${tag.href}/`}
              hrefLang={tag.lang}
            />
          )
        })}

        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={pageDescription} />
      </Helmet>
      <div
        className={classes}
        style={{
          backgroundColor: "background",
          backgroundImage: `url("https://res.cloudinary.com/vinomofo/image/upload/q_30/v1670258257/vinomofo-au/assets/preferences/bg-results_h4z2xi.jpg")`,
          backgroundSize: "cover",
        }}
      >
        <Row padding="2em"></Row>
        <Row padding="30px"></Row>

        <Heading as="h1" variant="xl" textAlign="center" color="white" fontFamily={'"GT Walsheim", sans-serif'}>
          {"That’s all folks!"}
        </Heading>
        <Row padding="0.5em"></Row>
        <Text
          textAlign="center"
          color="white"
          fontWeight="700"
          fontSize="20px"
          fontFamily={'"GT Walsheim", sans-serif'}
        >
          {localRanks[0] ? (`You said that you like ${localRanks[0]}
          ${localRanks[1] && localRanks[2] ? `, ${localRanks[1]} and ${localRanks[2]}` : 
            localRanks[1] ? ` and ${localRanks[1]}` : ''}
          so here are some wines we thought you might like.`) :
            'You haven\'t told us what you like yet. So we\'ve taken the liberty to choose some for you.'}
        </Text>
        <Row padding="0.75em"></Row>
        <Text
          textAlign="center"
          color="white"
          fontWeight="700"
          fontSize="20px"
          fontFamily={'"GT Walsheim", sans-serif'}
        >
          {
            localRanks[0] ? "Keep an eye on your inbox for personalised wine deals based on your selections today." : ''
          }
        </Text>
        <Row padding="30px"></Row>

      {
        page.sections.map((section, index) => {
         return PageSection.create(section, index)
        })
      }
        <Row padding="30px"></Row>

      </div>
    </PageLayout>
  )
}

export default PreferredWines
